












































import { LOCALES } from '@/localization/locales';
import { useGlobal } from '@/modules/global';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const { currentLocale, setLanguage } = useGlobal();

    return {
      LOCALES,
      currentLocale,
      setLanguage,
    };
  },
});
